import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { Loader } from "@googlemaps/js-api-loader";
import { MAP_LOADER_OPTIONS } from "./map.options";


export const googleMapCoreResolver: ResolveFn<google.maps.CoreLibrary> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<google.maps.CoreLibrary> => {
    const core_loader: Loader = new Loader(MAP_LOADER_OPTIONS);
    return core_loader.importLibrary("core");
  };

export const googleMapMarkerResolver: ResolveFn<google.maps.MarkerLibrary> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<google.maps.MarkerLibrary> => {
    const marker_loader: Loader = new Loader(MAP_LOADER_OPTIONS);
    return marker_loader.importLibrary("marker");
  };

export const googleMapMapsResolver: ResolveFn<google.maps.MapsLibrary> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<google.maps.MapsLibrary> => {
    const marker_loader: Loader = new Loader(MAP_LOADER_OPTIONS);
    return marker_loader.importLibrary("maps");
  };

export const googleMapVisualizationResolver: ResolveFn<google.maps.VisualizationLibrary> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<google.maps.VisualizationLibrary> => {
    const marker_loader: Loader = new Loader(MAP_LOADER_OPTIONS);
    return marker_loader.importLibrary("visualization");
  };

export const googleMapDrawingResolver: ResolveFn<google.maps.DrawingLibrary> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<google.maps.DrawingLibrary> => {
    const marker_loader: Loader = new Loader(MAP_LOADER_OPTIONS);
    return marker_loader.importLibrary("drawing");
  };
