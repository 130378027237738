import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { IUserInfo } from "./user.interface";


export const userDataResolver: ResolveFn<IUserInfo> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IUserInfo> => {
    const auth_service = inject(AuthService);
    return auth_service.getUser();
  };
