import { Component, OnInit, ApplicationRef } from "@angular/core";
import { AuthService } from "./common-services/auth.service";
import { Router, RouterOutlet } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { Platform } from "@angular/cdk/platform";
import { concat, interval } from "rxjs";
import { first, filter, map } from "rxjs/operators";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { NgIf } from "@angular/common";
import { ThemeService } from "./common-services/theme.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [RouterOutlet, NgIf, MatButtonModule, MatIconModule, TranslateModule]
})
export class AppComponent implements OnInit {
  public install_prompt_android: boolean;
  public install_prompt_ios: boolean;
  public update_prompt: boolean;
  private event_prompt: any;
  constructor(
    private theme_service: ThemeService,
    private authService: AuthService,
    private router: Router,
    private sw_update: SwUpdate,
    private platform: Platform,
    private app_ref: ApplicationRef) {

    this.install_prompt_android = false;
    this.install_prompt_ios = false;
    this.update_prompt = false;
    // if (!this.authService.isAuthenticated()) {
    //   this.router.navigate(["/login"]);
    // }

    if (this.router.url === "/login") {
      if (this.authService.isAuthenticated()) {
        this.router.navigate(["/app"]);
      }
    }

    // PWA checks code
    if (this.platform.ANDROID) {
      window.addEventListener("beforeinstallprompt", event => {
        event.preventDefault();
        this.event_prompt = event;
        this.install_prompt_android = true;
      }, { passive: true });
    }

    if (this.platform.IOS) {
      const key = "standalone";
      const isInStandaloneMode = (key in window.navigator) && (window.navigator[key]);
      if (!isInStandaloneMode) {
        this.install_prompt_ios = true;
      }
    }

    if (this.sw_update.isEnabled) {
      this.check_sf_update();
    }
  }

  ngOnInit() {
    this.theme_service.load_user_theme_settings();
    // New Update Available
    // this.sw_update.versionUpdates.subscribe(e => {
    //   if (!this.install_prompt_android || !this.install_prompt_ios) {
    //     this.update_prompt = true;
    //   }
    // });
    this.sw_update.versionUpdates.pipe(
      filter((evt: any) => evt.type === "VERSION_READY"),
      map(evt => ({
        type: "UPDATE_AVAILABLE",
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))).subscribe(e => {
        if (!this.install_prompt_android || !this.install_prompt_ios) {
          this.update_prompt = true;
        }
      });

    // check for new update every 5 minutes
    setInterval(() => {
      this.sw_update.checkForUpdate().then(update => {
        if (update) {
          this.update_prompt = true;
        }
      });
    }, 300000);
  }

  public enable_install(): void {
    this.event_prompt.prompt();
    this.install_prompt_android = true;
    this.close_prompt_android();
  }

  public close_prompt_android(): void {
    this.install_prompt_android = false;
  }

  public close_prompt_ios(): void {
    this.install_prompt_ios = false;
  }

  public close_prompt_update(): void {
    this.update_prompt = false;
  }

  public install_update(): void {
    this.update_prompt = false;
    window.location.reload();
  }

  private check_sf_update(): void {
    const app_stable = this.app_ref.isStable.pipe(first(isStable => isStable === true));
    const update_time_interval = interval(4 * 60 * 60 * 1000);
    const update = concat(app_stable, update_time_interval);
    update.subscribe(() => this.sw_update.checkForUpdate().then(() => console.log("checking for update")));
  }
}
