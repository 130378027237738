import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class MatPaginatorIntlService extends MatPaginatorIntl {
  constructor(private readonly translate_service: TranslateService) {
    super();

    this.translate_service.onLangChange.subscribe((e: Event) => {
      this.get_paginator_intl();
    });

    this.get_paginator_intl();
  }

  public get_paginator_intl(): void {
    this.translate_service.get([
      "MAT_PAG_ITEMS_PER_PAGE_LABEL",
      "MAT_PAG_NEXT_PAGE_LABEL",
      "MAT_PAG_PREVIOUS_PAGE_LABEL",
      "MAT_PAG_FIRST_PAGE_LABEL",
      "MAT_PAG_LAST_PAGE_LABEL"
    ]).subscribe((translation: any) => {
      this.itemsPerPageLabel = translation.MAT_PAG_ITEMS_PER_PAGE_LABEL;
      this.nextPageLabel = translation.MAT_PAG_NEXT_PAGE_LABEL;
      this.previousPageLabel = translation.MAT_PAG_PREVIOUS_PAGE_LABEL;
      this.firstPageLabel = translation.MAT_PAG_FIRST_PAGE_LABEL;
      this.lastPageLabel = translation.MAT_PAG_LAST_PAGE_LABEL;
      this.getRangeLabel = this.get_range_label.bind(this);

      this.changes.next();
    });
  }

  private get_range_label(page: number, page_size: number, length: number): string {
    if (length === 0 || page_size === 0) {
      return `0 ${this.translate_service.instant("OF")} ${length}`;
    }
    length = Math.max(length, 0);
    const start_index = page * page_size;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const end_index = start_index < length ? Math.min(start_index + page_size, length) : start_index + page_size;
    return `${start_index + 1} - ${end_index} ${this.translate_service.instant("OF")} ${length}`;
  }
}
