import { ResolveFn } from "@angular/router";
import init from "../../assets/wasm/poseyedon";


export const wasmResolver: ResolveFn<boolean> = (): Promise<any> => {
  return init("assets/wasm/poseyedon_bg.wasm").then(() => { });
};




