import { enableProdMode, APP_INITIALIZER, Injector, importProvidersFrom } from "@angular/core";
import { environment } from "./environments/environment";
import { AppComponent } from "./app/app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgxColorsModule } from "ngx-colors";
import { NgxFileDropModule } from "ngx-file-drop";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { NouisliderModule } from "ng2-nouislider";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppTranslateLoader } from "./app/common-services/translate-loader";
import { appRouter } from "./app/routes/route";
import { provideRouter } from "@angular/router";
import { provideAnimations } from "@angular/platform-browser/animations";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { GuidedTourService } from "./app/common-services/guidedTour.service";
import { CommonWorkerService } from "./app/common-services/common.worker.service";
import { ExportService } from "./app/common-services/export.service";
import { CommonShareService } from "./app/common-services/common.share.service";
import { DeviceShareService } from "./app/devices/device.share.service";
import { ToolbarService } from "./app/toolbar/toolbar.service";
import { UserService } from "./app/common-services/user.service";
import { AuthService } from "./app/common-services/auth.service";
import { AuthGuardService } from "./app/common-services/authGuard.service";
import { LoginService } from "./app/login/login.service";
import { MatPaginatorIntlService } from "./app/common-services/mat-paginator-intl.service";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { LANGUAGE_TYPE_LOCALE } from "./app/common-services/common.enum";
import { LOCATION_INITIALIZED } from "@angular/common";
import { TranslateService, TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpInterceptorService } from "./app/common-services/httpInterceptor.service";
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from "@angular/common/http";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { DateFnsAdapter, DateFnsModule, MAT_DATE_FNS_FORMATS } from "@angular/material-date-fns-adapter";
import { registerLocaleData } from "@angular/common";
import localeEn from "@angular/common/locales/en";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import localeFr from "@angular/common/locales/fr";
import localeFrExtra from "@angular/common/locales/extra/fr";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { DataShareService } from "./app/common-services/data.share.service";

registerLocaleData(localeEn, "en");
registerLocaleData(localeDe, "de", localeDeExtra);
registerLocaleData(localeFr, "fr", localeFrExtra);


export const app_initializer_factory = (translate_service: TranslateService, injector: Injector) => {
  return () => new Promise<any>((resolve: any) => {
    const lang_initialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    lang_initialized.then(() => {
      translate_service.setDefaultLang(LANGUAGE_TYPE_LOCALE.GBR);
      translate_service.use(LANGUAGE_TYPE_LOCALE.DEU).subscribe({
        next: () => { },
        error: (err) => { console.error(err); },
        complete: () => { resolve(null); }
      });
    });
  });
};


if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: AppTranslateLoader
        },
      }),
      FormsModule,
      ReactiveFormsModule,
      NouisliderModule,
      NgxSliderModule,
      NgxFileDropModule,
      NgxColorsModule,
      MatDialogModule,
      MatSnackBarModule,
      MatDatepickerModule,
      DateFnsModule,
      ServiceWorkerModule.register("ngsw-worker.js", {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: "registerWhenStable:30000"
      })),
    {
      provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: app_initializer_factory,
      deps: [TranslateService, Injector],
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlService,
    },
    {
      provide: DateAdapter, useClass: DateFnsAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        MAT_DATE_FNS_FORMATS,
        display: {
          dateInput: "dd. MMM yyyy",
          monthYearLabel: "MMM yyyy",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM yyyy"
        }
      }
    },

    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: "dynamic"
      }
    },
    LoginService,
    AuthGuardService,
    AuthService,
    UserService,
    ToolbarService,
    DeviceShareService,
    CommonShareService,
    DataShareService,
    ExportService,
    CommonWorkerService,
    GuidedTourService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(appRouter)
  ]
})
  .catch(err => console.error(err));
