import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, switchMap } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { DISABLE_REQUEST_CANCELLATION_HANDLING, HttpCancelService } from "./httpCancel.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { LoginService } from "../login/login.service";
import { environment } from "src/environments/environment";
import { DataShareService } from "./data.share.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private use_refresh_token: boolean = environment.env_type !== "PRODUCTION"; //todo - remove this as soon as refresh token is available on other environments
  private activate_token_expiry_check: boolean = true;

  constructor(
    private router: Router,
    private http_cancel_service: HttpCancelService,
    private snackbar: MatSnackBar,
    private translate_service: TranslateService,
    private login_service: LoginService,
    private data_share_service: DataShareService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let access_token_valid = true;
    if (this.exclude_urls(request.url)) {
      request = this.make_api_request(request);
      if (this.use_refresh_token && this.activate_token_expiry_check && this.check_access_token_expired()) {
        access_token_valid = false;
        // request a new token before actual request
        return this.refresh_token_handler(request, next);
      }
    }
    if (access_token_valid) {
      if (this.ignore_cancellation_handling(request)) {
        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => this.api_request_error_handler(request, next, error, true)));
      }
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => this.api_request_error_handler(request, next, error, true)),
        takeUntil(this.http_cancel_service.onCancel_pending_requests()));
    }
  }

  private ignore_cancellation_handling(request: HttpRequest<any>) {
    return request.context.get(DISABLE_REQUEST_CANCELLATION_HANDLING);
  }

  private refresh_token_handler(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // request a new token and execute original request afterwards
    return this.login_service.requestToken().pipe(
      switchMap((token) => {
        localStorage.setItem("access_token", token.access_token);
        localStorage.setItem("refresh_token", token.refresh_token);
        if (this.ignore_cancellation_handling(request)) {
          return next.handle(this.make_api_request(request)).pipe(
            catchError((error: HttpErrorResponse) => this.api_request_error_handler(request, next, error, false)));
        }
        return next.handle(this.make_api_request(request)).pipe(
          catchError((error: HttpErrorResponse) => this.api_request_error_handler(request, next, error, false)),
          takeUntil(this.http_cancel_service.onCancel_pending_requests()));
      }),
      catchError((switch_map_error) => {
        console.error("refresh_token error");
        // always logout on refresh token error
        this.router.navigate(["/login"]);
        this.data_share_service.clear_all_data();
        localStorage.clear();
        return throwError(() => switch_map_error);
      }));
  }

  private api_request_error_handler(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse,
    is_main_request: boolean): Observable<HttpEvent<any>> {
    if (error.status === 401) {
      if (this.use_refresh_token && is_main_request && this.exclude_urls_login_redirect(request.url)) {
        // request a new token but only once for is_main_request is true
        return this.refresh_token_handler(request, next);
      } else {
        this.router.navigate(["/login"]);
        const theme = localStorage.getItem("theme");
        const is_theme_auto = localStorage.getItem("is_theme_auto");
        this.data_share_service.clear_all_data();
        localStorage.clear();
        localStorage.setItem("theme", theme);
        localStorage.setItem("is_theme_auto", is_theme_auto);
      }
    } else if (error.status === 503) {
      this.snackbar.open(`${this.translate_service.instant("API_REQUEST_FAIL")}, ${this.translate_service.instant("DEPLOY_MODE_1_MSG")}`, "", {
        duration: 4000,
        panelClass: "error-background"
      });
    } else if (error.status === 400) {
      if (error.error === "invalid_grant") {
        this.router.navigate(["/login"]);
        const theme = localStorage.getItem("theme");
        const is_theme_auto = localStorage.getItem("is_theme_auto");
        this.data_share_service.clear_all_data();
        localStorage.clear();
        localStorage.setItem("theme", theme);
        localStorage.setItem("is_theme_auto", is_theme_auto);
      }
    } else {
      // suppress message if iot installation table entry not found
      try {
        if (error.error.indexOf("device installation info not found") === -1) {
          this.snackbar.open(this.translate_service.instant("API_REQUEST_FAIL"), "", {
            duration: 4000,
            panelClass: "error-background"
          });
          console.error(error);
        }
      } catch { }
    }
    return throwError(() => error);
  }

  private make_api_request(request: HttpRequest<any>) {
    return request.clone({
      headers: request.headers.set("Authorization", `Bearer ${localStorage.getItem("access_token")}`),
      url: request.url
    });
  }

  private exclude_urls(url: string): boolean {
    const url_list = ["/oauth/token", "/oauth2/token", "users/reset-password", "users/validate-reset-password", "users/change-password",
      "users/send-activation", "users/validate-activate-user-password", "users/change-email", "text/lookup"];
    const isExist = url_list.find(list => url.includes(list));
    if (!isExist) {
      return true;
    } else {
      return false;
    }
  }

  private exclude_urls_login_redirect(url: string): boolean {
    const url_list = ["users/validate-activate-user-password", "users/validate-reset-password", "users/change-email"];
    const isExist = url_list.find(list => url.includes(list));
    if (!isExist) {
      return true;
    } else {
      return false;
    }
  }

  private check_access_token_expired(): boolean {
    let is_expired = true;
    try {
      const token_expiry = (JSON.parse(atob(localStorage.getItem("access_token").split(".")[1]))).exp;
      is_expired = (Math.floor((new Date).getTime() / 1000)) >= token_expiry;
    } catch { }
    return is_expired;
  }
}
